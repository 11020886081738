import { Trans, useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";

const RentACenterBiometricsPage = () => {
    const { t } = useTranslation();
    return (
        <Col xs={12}>
            <p>{t("rentacenter.biometrics1")}</p>
            <Trans
                i18nKey='rentacenter.biometrics2'
                components={{
                    1: <a className='text-primary' href={t("rentacenter.biometricsLink")} aria-label="Opens Rent-A-Center's Biometric Policy." />,
                }}
            />
        </Col>
    );
};
export default RentACenterBiometricsPage;
