import Background from "../../../components/Background/Background.tsx";
import { useTranslation } from "react-i18next";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";

const DrippinDataCompletePage = () => {
    const { t } = useTranslation();

    return (
        <Background>
            <LogoAndTimer />
            <div className='d-flex flex-column justify-content-center align-items-center text-center h-100 mt-5'>
                <div>
                    <h1 className='text-success fw-bold'>{t("completePage.submitted")}</h1>
                    <h3 className='fw-bold'>{t("completePage.complete")}</h3>
                </div>
                <p className='text-center'>{t("completePage.close")}</p>
            </div>
        </Background>
    );
};

export default DrippinDataCompletePage;
