import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Background from "../../../components/Background/Background.tsx";

const RegionsCompletePage = () => {
    const { t } = useTranslation();

    return (
        <Background>
            <Stack gap={2} className='text-center mt-3'>
                <h1>{t("regions.completeHeader")}</h1>
                <p>{t("regions.completeText")}</p>
            </Stack>
        </Background>
    );
};

export default RegionsCompletePage;
