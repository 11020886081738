import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const YahooMailCompletePage = () => {
    const { t } = useTranslation();
    return (
        <Stack gap={3} className='text-center'>
            <h2 className='fw-bold'>{t("yahoomail.completeHeader")}</h2>
            <p>{t("yahoomail.completep1")}</p>
        </Stack>
    );
};

export default YahooMailCompletePage;
