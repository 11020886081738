import { useTranslation } from "react-i18next";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { Stack } from "react-bootstrap";
import React from "react";
import { useLocation } from "react-router-dom";
import Background from "../../../components/Background/Background.tsx";

const RegionsErrorPage = () => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get("token");

    return (
        <Background>
            <LogoAndTimer />
            <Stack gap={2} className='text-center mt-3'>
                <p>{t("regions.error")}</p>
                <p className='fw-bold'>{token}</p>
            </Stack>
        </Background>
    );
};

export default RegionsErrorPage;
