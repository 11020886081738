import { Stack } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

const YahooMailBiometricsPage = () => {
    const { t } = useTranslation();
    return (
        <Stack gap={3} className='text-center'>
            <h2 className='fw-bold'>Agree to the Terms</h2>
            <p>
                <Trans
                    i18nKey={"yahoomail.selfiep1"}
                    components={{
                        1: (
                            <a
                                href={t("selfieConsentModal.biometricsPolicy")}
                                className='link-dark'
                                aria-label='Intellicheck Biometrics Policy Website'
                            />
                        ),
                    }}
                />
            </p>
        </Stack>
    );
};
export default YahooMailBiometricsPage;
