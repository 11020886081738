import { useTranslation } from "react-i18next";
import FirstAmericanImage from "../../../assets/FirstAmericanTitle/FirstAmericanTitleLandingImage.svg";
import { Button, Stack } from "react-bootstrap";
import createLogEventBody from "../../../utils/createLogEventBody";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.ts";
import { useLogEventMutation } from "../../../api/api.ts";

const FirstAmericanTitleLanding = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const [logEvent] = useLogEventMutation();

    const clickStart = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <Stack gap={3} className='justify-content-center align-items-center text-center'>
            <img
                className='col-8 col-md-12 my-4'
                src={FirstAmericanImage}
                alt='image of checkmark on shield'
                aria-label='image of checkmark on shield'
            />
            <Stack gap={2} className='d-flex align-items-center'>
                <h1 className='fw-bold'>
                    {captureReqs.length} - {t("landingPage.step")}
                </h1>
                <h2 className='text-center fw-bold'>{t("landingPage.identityValidation")}</h2>
                <p>
                    {t("landingPage.privacyPolicyMessage")}{" "}
                    <a
                        onClick={() => logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status))}
                        href={t("landingPage.privacyPolicyLink")}
                        target='_blank'
                    >
                        {t("landingPage.privacyPolicy")}
                    </a>
                    .
                </p>
                <Button variant='primary' onClick={clickStart} className='col-7 col-md-5 p-2 p-md-3'>
                    {t("landingPage.start")}
                </Button>
            </Stack>
        </Stack>
    );
};

export default FirstAmericanTitleLanding;
