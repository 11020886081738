import { Button, Stack } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store.ts";
import createLogEventBody from "../../../../utils/createLogEventBody";
import CustJourneyCodes from "../../../../assets/CustomerJourneyCodes.json";
import { useLogEventMutation } from "../../../../api/api.ts";
import placeholder from "../../../../assets/Yahoo/YahooMailPlaceholder.jpg";

const YahooMailLandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const [logEvent] = useLogEventMutation();

    const clickStart = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <div className='h-100 d-flex justify-content-between flex-column text-center'>
            <Stack gap={3}>
                <img className='w-50 mx-auto' src={placeholder} alt='placeholder' />
                <h2 className='fw-bold'>{t("yahoomail.landingHeader")}</h2>
                <p>
                    <Trans
                        i18nKey={"yahoomail.landingp1"}
                        components={{
                            1: <a href={"https://www.intellicheck.com/"} className='link-dark' aria-label='Intellicheck Website' />,
                        }}
                    />
                </p>
                <p>{t("yahoomail.landingp2")}</p>
                <Button variant='primary' onClick={clickStart} className='mb-2'>
                    {t("yahoomail.landingBtn")}
                </Button>
            </Stack>
            <p>
                <Trans
                    i18nKey={"yahoomail.privacyPolicy"}
                    components={{
                        1: (
                            <a
                                href={"https://legal.yahoo.com/us/en/yahoo/terms/otos/index.html"}
                                className='link-dark'
                                aria-label='Yahoo Terms Website'
                            />
                        ),
                        2: (
                            <a
                                href={"https://legal.yahoo.com/us/en/yahoo/privacy/index.html"}
                                className='link-dark'
                                aria-label='Yahoo Privacy Policy Website'
                            />
                        ),
                        3: <a href={t("landingPage.privacyPolicyLink")} className='link-dark' aria-label='Intellicheck Privacy Policy Website' />,
                    }}
                />
            </p>
        </div>
    );
};
export default YahooMailLandingPage;
